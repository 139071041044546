import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Checkbox, Form, Input } from "antd";
import { login } from "../../store";
import { useNavigate } from "react-router";
import { ACCOUNT_PAGE, PASSWORD_RESET_REQUEST } from "../../configuration/paths";
import { save } from "../../store/localstorage";
import "./styles/login.scss"

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const loggedIn = useSelector(state => state.app.auth.token)

  useEffect(() => {
    if (loggedIn) {
      navigate(ACCOUNT_PAGE)
    }
  }, [loggedIn])

  const onFinish = (values) => {
    dispatch(login({
      ...values,
      successCallback: data => {
        if (data.message) {
          setError('Username or Password is incorrect. Please try again.')
        } else {
          if (values.remember) {
            save("auth_token", data.token)
          }
          navigate(ACCOUNT_PAGE);
        }
      }
    }));
  };

  return (

    <div style={{ padding: '10px' }}>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        className="login-form"
      >
        <Form.Item
          label="Login"
          name="username"
          rules={[
            {
              required: true,
              message: 'Input username',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Input password',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Login
          </Button>
        </Form.Item>
        <div>
          <Button
            className="reset_button"
            type="text"
            onClick={e => navigate(PASSWORD_RESET_REQUEST)}
          >
            <u>Forgot Password</u>
          </Button>
        </div>
        <p
          style={{ color: 'red', marginTop: '15px' }}
        >
          {
            error
          }
        </p>
      </Form>
    </div>
  );
}

export default Index;
import { Divider } from "antd"

export const ConfigureDevice = ({serialNumber, vehicleName, deviceVersion, appVersion, homography, azimuthOffset, elevationOffset, lightsThreshold, sirensThreshold}) => {
    return (
        <div>
            <h1>{vehicleName}</h1>
            <h3>{serialNumber}</h3>
            <Divider />
            <p>Device Version: {deviceVersion}</p>
            <p>App Version: {appVersion}</p>
            <p>Homography: {homography}</p>
            <p>Azimuth Offset: {azimuthOffset}</p>
            <p>Elevation Offset: {elevationOffset}</p>
            <p>Lights Threshold (sec): {lightsThreshold}</p>
            <p>Sirens Threshold (sec): {sirensThreshold}</p>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Layout, Menu, Affix } from "antd";
import { ACCOUNT_PAGE, ALARM_HISTORY, ALARM_HISTORY_SUMMARY, HOME_PAGE, LOGIN_PAGE, MANAGEMENT_PAGE, DEVICES_DASHBOARD, SETTINGS_PAGE, UPTIME_HISTORY } from "../../configuration/paths";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateAuth } from "../../store";
import { remove } from "../../store/localstorage";

const { Header, Content, Footer } = Layout;

const loggedOutItems = [
  {
    key: LOGIN_PAGE,
    label: "Log in"
  },
]

const Index = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const loggedIn = !!useSelector(state => state.app.auth.token)
  const role = useSelector(state => state.app.profile.role)
  const DASHBOARD_OBJ = {
    key: `dashboard_menu`,
    label: "Dashboards",
    children: [
      {
        key: ACCOUNT_PAGE,
        label: 'Map Dashboard'
      },
      {
        key: DEVICES_DASHBOARD,
        label: 'RAD Dashboard'
      }
    ]
  }
  const ALARM_HISTORY_OBJ = {
    key: `${ALARM_HISTORY}_menu`,
    label: "History",
    children: [
      {
        key: ALARM_HISTORY,
        label: 'Alarms history'
      },
      {
        key: ALARM_HISTORY_SUMMARY,
        label: 'Alarms Summary'
      },

    ]
  }
  const SETTINGS_OBJ = {
    key: `accout${SETTINGS_PAGE}`,
    label: "Settings",
    children: [
      {
        key: SETTINGS_PAGE,
        label: 'Profile Settings'
      },
    ]
  }
  const [loggedInItems, setLoggedInItems] = useState([
    {
      key: ACCOUNT_PAGE,
      label: "Dashboard"
    },
    ALARM_HISTORY_OBJ,
    SETTINGS_OBJ,
    {
      key: LOGIN_PAGE,
      label: "Log out"
    }
  ])

  useEffect(() => {
    if (loggedIn) {
      dispatch(getProfile())
    }
  }, [loggedIn, dispatch])

  useEffect(() => {
    setLoggedInItems(lI => lI.map((item, i) => {
      if (i === 0) {
        return role === 'special' ? DASHBOARD_OBJ : item
      } else if (i === 1) {
        // TODO: Remove this part of the code when not all the vehilces are being show to all users
        if (role !== 'user') {
          return {
            ...item,
            children: [item.children[0], item.children[1], {
              key: UPTIME_HISTORY,
              label: 'Traffic Metrics',
            }]
          }
        } else {
          return ALARM_HISTORY_OBJ
        }
      } else if (i === 2) {
        if (role !== 'user' && role !== 'analyst') {
          return {
            ...item,
            children: [item.children[0], { key: MANAGEMENT_PAGE, label: 'Manage Groups' }]
          }
        } else {
          return SETTINGS_OBJ
        }
      } else {
        return item
      }
    }))
  }, [role])

  return (
    <Layout className="layout">
      <Affix offsetTop={0}>
        <Header>
          <div className="logo" onClick={() => navigate(HOME_PAGE)} />

          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[pathname]}
            items={loggedIn ? loggedInItems : loggedOutItems}
            onSelect={item => {
              if (item.key === LOGIN_PAGE && loggedIn) {
                dispatch(updateAuth({ token: null }));
                remove("auth_token")
              }
              navigate(item.key)
            }} />
        </Header>
      </Affix>
      <Content>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        {`Lantern LLC © ${new Date().getFullYear()}`}
      </Footer>
    </Layout>
  );
}

export default Index;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router";
import { getDeviceConfiguration, getDevices } from "../../store";
import { ListDevices } from "./components/ListDevices";
import './styles/index.scss'
import { ConfigureDevice } from "./components/ConfigureDevice";
import { ACCOUNT_PAGE } from "../../configuration/paths";

export const DeviceDashboard = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const role = useSelector(state => state.app.profile.role);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(undefined);
    const [deviceInformation, setDeviceInformation] = useState({})

    const goTo = (serialNumber) => {
        navigate(`${location.pathname}?device=${serialNumber}`)
    }

    const parseDeviceInformation = (serialNumber, configuration) => {
        const device = devices.find((d) => d.serial_number === serialNumber)
        setDeviceInformation({
            serialNumber: serialNumber,
            vehicleName: device.vehicle_name,
            deviceVersion: configuration.device_version,
            appVersion: configuration.app_version,
            homography: configuration.homography,
            azimuthOffset: configuration.azimuth_offset,
            elevationOffset: configuration.elevation_offset,
            lightsThreshold: configuration.lights_threshold,
            sirensThreshold: configuration.sirens_threshold
        })
    }

    useEffect(() => {
        if (role && role !== 'special') {
            navigate(ACCOUNT_PAGE)
        }
    }, [role])

    useEffect(() => {
        dispatch(getDevices())
            .then((response) => {
                setDevices(response.payload)
            })
    }, [dispatch])

    useEffect(() => {
        setSelectedDevice(location.search.split('=')[1])
        const device = location.search.split('=')[1]
        if (device) {
            dispatch(getDeviceConfiguration(device))
                .then((response) => {
                    parseDeviceInformation(device, response.payload)
                })
        }
    }, [devices, location])

    useEffect(() => {
        if (selectedDevice) {
            // console.log(selectedDevice)
        }
    }, [selectedDevice])

    useEffect(() => {
        console.log(deviceInformation)
    }, [deviceInformation])

    return (
        <div className="main-container">
            {
                selectedDevice ? <ConfigureDevice {...deviceInformation} />
                    : <ListDevices devices={devices} goTo={goTo} />
            }
        </div>
    )
}
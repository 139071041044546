import '../styles/DeviceButton.scss'

export const DeviceButton = ({ serialNumber, vehicleName, goTo }) => {
    return (
        <div className='device-button' onClick={() => goTo(serialNumber)}>
            <div className='textDiv'>
                <p className='text'>{vehicleName}</p>
                <p className='text'>({serialNumber})</p>
            </div>
        </div>
    )
}
import { Divider } from 'antd'
import { DeviceButton } from "./DeviceButton"
import '../styles/ListDevices.scss'
import { useEffect } from 'react'

export const ListDevices = ({ devices, goTo }) => {
    return (
        <div className='container'>
            <h1>Devices</h1>
            <Divider style={{ marginTop: "10px" }} />
            <div className='list'>
                {devices.map((device, i) => <DeviceButton key={i} serialNumber={device.serial_number} vehicleName={device.vehicle_name} goTo={goTo} />)}
            </div>
        </div>
    )
}
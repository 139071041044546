import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import Navigation from "../components/nav";
import * as paths from "./paths";
import ScrollToTop from "./ScrollToTop";
import Landing from "../components/landing";
import Login from "../components/login";
import Account from "../components/dashboard";
import { DeviceDashboard } from "../components/devicedashboard";
import AlarmHistory from "../components/alarmhistory";
import PrivacyPage from "../components/privacy";
import { ForgotPassword } from "../components/forgotpassword";
import Settings from "../components/settings";
import { AlarmHistorySummary } from "../components/alarmhistorysummary";
import { Management } from "../components/management";
import { NewAccount } from "../components/newAccount";
import { UptimeHistory } from "../components/uptimehistory";
import { MetricsDashboard } from "../components/uptimehistory/MetricsDashboard";
// import { NotifyGroup } from "../tests/NotifyGroup";
// import { FilterTest } from "../tests/FilterTest";

const AppRoutes = () => {
  const loggedIn = !!useSelector(state => state.app.auth.token)

  const element = (reactElement, loggedIn) => {
    if (loggedIn) {
      return reactElement
    } else {
      return <Navigate to={paths.LOGIN_PAGE} replace />
    }
  }

  return (
    <Router>
      <ScrollToTop />
      <Navigation>
        <Routes>
          <Route exact path={paths.HOME_PAGE} element={<Landing />} />
          <Route exact path={paths.LOGIN_PAGE} element={<Login />} />
          <Route exact path={paths.ACCOUNT_PAGE} element={element(<Account />, loggedIn)} />
          <Route exact path={paths.DEVICES_DASHBOARD} element={element(<DeviceDashboard />, loggedIn)} />
          <Route exact path={paths.ALARM_HISTORY} element={element(<AlarmHistory />, loggedIn)} />
          <Route exact path={paths.ALARM_HISTORY_SUMMARY} element={element(<AlarmHistorySummary />, loggedIn)} />
          {/* <Route exact path={paths.UPTIME_HISTORY} element={<UptimeHistory />} /> */}
          <Route exact path={paths.UPTIME_HISTORY} element={element(<MetricsDashboard />, loggedIn)} />
          <Route exact path={paths.PRIVACY_PAGE} element={<PrivacyPage />} />
          <Route exact path={paths.PASSWORD_RESET_REQUEST} element={<ForgotPassword />} />
          <Route exact path={paths.PASSWORD_RESET_PAGE} element={<ForgotPassword />} />
          <Route exact path={paths.SETTINGS_PAGE} element={element(<Settings />, loggedIn)} />
          <Route exact path={paths.MANAGEMENT_PAGE} element={element(<Management />, loggedIn)} />
          <Route exact path={paths.NEW_ACCOUNT_PAGE} element={<NewAccount />} />
          {/* <Route exact path={TEST_PAGE} element={<FilterTest />} /> */}
        </Routes>
      </Navigation>
    </Router>
  );
};

export default AppRoutes;
